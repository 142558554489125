<template>
	<div class="groupManage" v-loading="loading">
		<div class="notice">
			<div v-if="onlineStatus.is_view">
				<div class="box-item">
					<div class="item-l">
						当前状态：{{onlineStatus.status == true ? '在线中' : '已离线'}}
						<div v-if="onlineStatus.status == true" class="round"
							style="background: green;width:10px;height:10px"></div>
						<div v-else class="round" style="background: #a9a9a9;width:10px;height:10px"></div>
					</div>
					<el-button type="danger" size="mini" v-if="onlineStatus.status == true" @click.stop="loginFn(2)">退
						出</el-button>
					<el-button type="success" size="mini" v-else @click.stop="loginFn(1)">登 录</el-button>
				</div>
				<div class="box-item">
					<div class="item-l">
						有效期至：{{onlineStatus.expire}} (如需试用或续费请使用微信小程序进行操作)
					</div>
				</div>
				<div class="box-item">
					<div class="item-l">
						<div>
							<span>使用须知：请务必使用完成实名认证的微信账号登录；请合理使用小助手，以免官方限制功能,若消息无法推送至群组，请参考</span>
							<a href='https://static.tuanhuo.howtec.cc/miniprogram/common/wx_help.jpg'
								target="_blank"><img src="https://static.tuanhuo.howtec.cc/images/xiaotu.png"
									style="width: 30px;height: 30px;vertical-align: middle;margin: 0 10px;" /></a>
							<span>设置后重新登录再试。</span>
						</div>
					</div>
					<div class="item-r"></div>
				</div>
			</div>
			<div v-else>
				<p>点此下载<a href="#" @click.stop="pwdVisible = true" style="font-weight: bold;color: #fc7414;">微信个人号助手
						最近更新：2024-09-29</a>
				</p>
				<p>1、如未配置自动回复时间段，则任意时间均触发自动回复。</p>
				<p>2、如开启下单推送，则有用户下单时将向指定的群组中推送新用户下单消息。</p>
			</div>
		</div>
		<div class="table">
			<el-form ref="form" :model="form" label-width="140px">
				<el-form-item label="自动回复时间段">
					<el-time-picker is-range v-model="timeRange" range-separator="至" start-placeholder="开始时间"
						value-format="HH:mm:ss" end-placeholder="结束时间" placeholder="选择时间范围" @change="confirmTimeRange">
					</el-time-picker>
				</el-form-item>
				<el-form-item label="已选时间段">
					<el-tag v-for="(item,index) in form.reply_time_arr" :key="index" closable="true"
						@close="removeTimeRange(index)" style="margin-right:10px">
						{{ item }}
					</el-tag>
				</el-form-item>
				<el-form-item label="未命中关键词" required>
					<el-radio-group v-model="form.no_hit_reply">
						<el-radio :label="0">不回复</el-radio>
						<el-radio :label="1">回复店铺首页链接</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="下单推送" required>
					<el-radio-group v-model="form.order_push">
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="2">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="群组：" v-if="form.order_push == 1" required>
					<el-checkbox-group v-model="tmpTarget">
						<el-checkbox v-for="(item,index) in wxGroupList" :key="index" :label="item.id"
							:checked="item.checked" @change="checkGroup">{{ item.nickname
                   }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</div>
		<el-dialog title="密码" :visible.sync="pwdVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="formDown" :model="formDown" label-width="80px">
				<el-form-item label="登录密码" required>
					<el-input type="password" v-model="formDown.password" placeholder="请输入登录密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" required>
					<el-input type="password" v-model="formDown.password_confirmation" placeholder="请确认密码"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="pwdVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmDown">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="loginDialog" :close-on-click-modal="false" v-el-drag-dialog width="500px"
			@close="closeLoginDialog">
			<div style="display: flex;flex-direction: column;align-items: center;">
				<span>请使用微信扫一扫登录</span>
				<img :src="wxLoginImg" style="width:300px;height:300px;">

			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="closeLoginDialog">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import CryptoJS from 'crypto-js'
	var timestamp = new Date().getTime()
	export default {
		data() {
			return {
				timeRange: "",
				formDown: {},
				loading: true,
				wxList: [],
				wxGroupList: [],
				tmpTarget: [],
				form: {
					no_hit_reply: "1",
					order_push: "2",
					order_push_target: []
				},
				pwdVisible: false,
				onlineStatus: {
					is_view: true
				},
				wxLoginImg: "",
				loginDialog: false,
			}
		},
		mounted() {
			this.getIndex()
			this.getOnlineStatus()
			// this.$nextTick(() => {
			// 	this.getWxAll()
			// })
		},
		methods: {
			checkGroup() {
				console.log(this.tmpTarget)
			},
			confirmDown() {
				this.$http.post(`/erp/v1/wx_groups/download`, {
					...this.formDown
				}).then((res) => {
					this.pwdVisible = false
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data)
					} else {
						this.$message.error(msg);
					}
				});
			},

			confirmTimeRange(val) {
				this.form.reply_time_arr.push(val[0] + "-" + val[1])
				console.log(this.form.reply_time_arr)
			},
			removeTimeRange(index) {
				this.form.reply_time_arr.splice(index, 1);
				console.log(this.form.reply_time_arr)
			},
			// 获取配置微信
			getIndex() {
				this.$http.post(`/erp/v1/wx_setting/index`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.form = data
						data.order_push_target.forEach(item => {
							this.tmpTarget.push(Number(item))
						})
						this.getWxAll()
						//console.log("form", this.form.order_push_target)
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				console.log(this.form);
				if (this.form.order_push == 1 && this.tmpTarget.length <= 0) {
					this.$message.error('请选择下单推送的群组');
					return
				}
				if (this.form.order_push == 1) {
					this.$set(this.form, "order_push_target", this.tmpTarget.join(","))
				}

				if (this.form.reply_time_arr.length > 0) {
					this.form.reply_time = this.form.reply_time_arr.join(",")
				}
				let url = '/erp/v1/wx_setting/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						window.location.reload()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 微信群组
			getWxAll() {
				console.log("form1", this.form)
				let that = this
				that.wxUserList = []
				that.wxGroupList = []
				this.$http.post(`/erp/v1/wx_groups/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						that.wxList = data
						that.wxList.forEach(item => {
							if (item.type == 2) {
								that.wxGroupList.push(item)
							}
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			getOnlineStatus() {
				this.$http.post(`/erp/v1/wx_setting/status`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.onlineStatus = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			closeLoginDialog() {
				this.loginDialog = false
				this.getOnlineStatus()
			},
			loginFn(type) {
				this.loading = true
				if (type == 1) {
					this.$http.post(`/erp/v1/wx_setting/login`).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						this.loading = false
						if (code == 200) {
							this.loginDialog = true
							this.wxLoginImg = data.url
						} else {
							this.$message.error(msg);
						}
					});
				} else {
					this.$http.post(`/erp/v1/wx_setting/logout`).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						this.loading = false
						if (code == 200) {
							this.getOnlineStatus()
						} else {
							this.$message.error(msg);
						}
					});
				}
			},
		},
	}
</script>

<style>
	.notice {
		background: #fff;
		margin-bottom: 20px;
		padding: 20px;
		border-radius: 10px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #FF720E;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #FF720E;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 125PX;
		height: 125PX;
		line-height: 125PX;
		text-align: center;
	}

	.avatar {
		width: 125PX;
		height: 125PX;
		display: block;
	}

	.box-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f7f7f7;
		padding: 30rpx 0rpx;
		margin: 0 30rpx;


	}

	.box-item .item-l {
		display: flex;
		align-items: center;

		.round {
			width: 16rpx;
			height: 16rpx;
			border-radius: 50%;
			margin-left: 10rpx;
		}
	}

	.box-item .item-r-button {
		background-color: #fa8919;
		color: #fff;
		padding: 8rpx 20rpx;
		border-radius: 10rpx;
	}
</style>